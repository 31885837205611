import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faSort,
    faSortDown,
    faSortUp,
    faCaretUp,
    faCaretDown,
    faEye,
    faEyeSlash,
    faTimes,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faSort as IconDefinition,
    faSortDown as IconDefinition,
    faSortUp as IconDefinition,
    faCaretUp as IconDefinition,
    faCaretDown as IconDefinition,
    faEye as IconDefinition,
    faEyeSlash as IconDefinition,
    faTimes as IconDefinition,
    faPlus as IconDefinition
);
